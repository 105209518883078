import { Box, Container } from "@mui/material";
import styles from "./AuthLayout.module.scss";

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import Logo from "../../assets/images/svgs/Bria-logo.svg";
import {LoadingPlaceholder} from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import RouterConstants from "../../constants/RouterConstants";
import { useAppStore } from "../../hooks/useStores.tsx";

interface LayoutProps {
	ignoreRedirect?: boolean;
}

const AuthLayout = ({ ignoreRedirect = false }: LayoutProps) => {
	const { authStore } = useAppStore();

	const getContent = () => {
		return (
			<Container component="main" maxWidth={false} className={clsx(styles.background, styles.container)}>
				<Box className={styles.box}>
					<img src={Logo} className={styles.logo} />
				</Box>
				<Box className={styles.credentials_content}>
					<Outlet />
				</Box>
			</Container>
		);
	};
	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading}>
			{authStore.isLoggedIn && !ignoreRedirect ? (
				<Navigate to={`${RouterConstants.HOME.path}?${new URLSearchParams(location.search)}`} />
			) : (
				getContent()
			)}
		</LoadingPlaceholder>
	);
};

export default observer(AuthLayout);
