import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import BriaIframe from "../../components/common/BriaIframe/BriaIframe";
import { PagesEnum } from "../../constants/IframeConstants";
import styles from "./Campaign.module.scss";
import {useLocation} from "react-router-dom";

const Campaign = () => {
	const location = useLocation();

	return (
		<Box className={styles.container}>
			<BriaIframe
				vhash={location.state?.vhash}
				imageUrl={location.state?.imageUrl}
				iframeId="accenture-1"
				target={PagesEnum.Campaign}
			/>
		</Box>
	);
};

export default observer(Campaign);
