import { Button, ButtonProps } from "@mui/material";
import { CSSProperties, FC } from "react";
import styles from "./BriaButton.module.scss";

export enum BUTTONTYPES {
	PRIMARY = "primary",
	OUTLINED = "outlined",
	TERTIARYMEDIUM = "tertiaryMedium",
	SECONDARYMEDIUM = "secondaryMedium",
	TEXTSMALL = "textSmall",
	PRIMARY_MEDIUM = "primaryMedium",
	TEXT_MEDIUM = "textMedium",
}

interface IProps {
	buttonType?: BUTTONTYPES;
	style?: CSSProperties;
	className?: any;
}

const BriaButton: FC<IProps & ButtonProps> = ({ children, buttonType, style, className, ...rest }) => {
	return (
		<Button
			{...rest}
			style={style}
			className={`${buttonType ? styles[buttonType] : ""} ${styles.textCommonStyle} ${className}`}
		>
			{children}
		</Button>
	);
};

export default BriaButton;
