import ErrorIcon from "@mui/icons-material/Error";
import { Alert, Box, CircularProgress, Link, Typography } from "@mui/material";
import { GithubAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { Form, Formik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Github from "../../../assets/images/icons/Github.tsx";
import Google from "../../../assets/images/icons/Google.tsx";
import BriaButton, { BUTTONTYPES } from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import { isValidEmail } from "../../../utils/validators.ts";
import classes from "./Login.module.scss";

interface FormValues {
	email: string;
	password: string;
}

const Login = () => {
	const initialValues = {
		email: "",
		password: "",
	};
	const location = useLocation();

	const navigate = useNavigate();
	const { t } = useTranslation();
	const { loginWithEmail, authenticateWithPopup, loading, errorMessage, setErrorMessage } = useAuthService();

	const handleSubmit = async (values: any) => {
		await loginWithEmail(values.email, values.password, async () => {
			navigate(RouterConstants.HOME.path);
		});
	};

	const signInUsingProvider = async (authProvider: any) => {
		await authenticateWithPopup(authProvider);
		navigate(RouterConstants.HOME.path);
	};

	if (errorMessage === "Firebase: Error (auth/user-disabled).") {
		navigate(LinkConstants.CONTACT_US_FORM_LINK);
	}
	return (
		<Box className={classes.content}>
			<Typography className={classes.mainTitle}>{t("welcomeBack")}</Typography>
			{location?.state?.passwordReset && (
				<Alert severity="success" className={classes.success}>
					t("passwordHasBeenReset")
				</Alert>
			)}

			<Box className={classes.signInWithContainer}>
				<BriaButton
					buttonType={BUTTONTYPES.OUTLINED}
					variant="outlined"
					startIcon={<Google className={classes.icon} />}
					onClick={async () => {
						signInUsingProvider(new GoogleAuthProvider());
					}}
				>
					{t("signInWithGoogle")}
				</BriaButton>
				<BriaButton
					buttonType={BUTTONTYPES.OUTLINED}
					variant="outlined"
					startIcon={<Github className={classes.icon} />}
					onClick={async () => {
						signInUsingProvider(new GithubAuthProvider());
					}}
				>
					{t("signInWithGithub")}
				</BriaButton>
			</Box>

			<Typography className={classes.or}>{t("or")}</Typography>

			<Formik
				initialValues={initialValues}
				validateOnMount={false}
				validateOnChange={false}
				validateOnBlur={false}
				validate={(values) => {
					const errors: FormikErrors<FormValues> = {};
					if (!values.email) {
						errors.email = t("emailIsRequired");
					} else if (!isValidEmail(values.email)) {
						errors.email = t("invalidEmail");
					}

					if (!values.password) {
						errors.password = t("passwordIsRequired");
					}
					if (Object.keys(errors).length !== 0) {
						setErrorMessage("");
					}

					return errors;
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, handleChange, handleBlur }) => (
					<Form className={classes.form} noValidate>
						<Box className={classes.formElements}>
							<BriaInput
								id="email"
								label={t("email")}
								onChange={handleChange}
								error={(Boolean(errors.email) && touched.email) as boolean}
								onBlur={handleBlur}
								helperText={touched.email ? errors.email : ""}
								className={classes.textField}
							/>
							<BriaInput
								id="password"
								label={t("password")}
								type="password"
								autoComplete="current-password"
								onChange={handleChange}
								onBlur={handleBlur}
								error={(Boolean(errors.password) && touched.password) as boolean}
								helperText={touched.password ? errors.password : ""}
								className={classes.textField}
							/>

							{errorMessage && (
								<Box className={classes.errorWrapper}>
									<Typography className={classes.error} color="secondary">
										<ErrorIcon className={classes.errorIcon} />
										{t(`${errorMessage}`.replace(/\s/g, "").replace(":", ""))}
									</Typography>
								</Box>
							)}

							<BriaButton
								buttonType={BUTTONTYPES.PRIMARY}
								type="submit"
								variant="contained"
								color="secondary"
								fullWidth={true}
							>
								{t("logIn")}
								<Box className={classes.circleLoader}>
									{loading && <CircularProgress color="inherit" size={25} />}
								</Box>
							</BriaButton>

							<Box className={classes.forgetPassword}>
								<Link
									onClick={() => {
										navigate(RouterConstants.FORGOT_PASSWORD.path);
									}}
								>
									{t("forgotYourPassword?")}
								</Link>
							</Box>

							<Typography className={classes.alreadyHaveAnAccount}>
								{t("dontHaveAnAccount")}
								<Link
									onClick={() => {
										navigate(RouterConstants.SIGNUP.path);
									}}
									className={classes.signup}
								>
									{t("signUp")}
								</Link>
							</Typography>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default Login;
