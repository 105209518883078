import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import { LoadingPlaceholder } from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../constants/RouterConstants";
import { useAppStore } from "../../hooks/useStores";
import styles from "./PrivateLayout.module.scss";

interface LayoutProps {
	ignoreUserChecks?: boolean;
}

const PrivateLayout = ({ ignoreUserChecks = false }: LayoutProps) => {
	const { authStore } = useAppStore();

	const getContent = () => {
		if (!authStore.isLoggedIn) {
			return <Navigate to={RouterConstants.LOGIN.path} />;
		} else {
			if (!ignoreUserChecks && !authStore?.isError) {
				if (!(authStore.user?.getInfo ?? true)) {
					return <Navigate to={RouterConstants.USER_INFO.path} />;
				} else {
					return <Outlet />;
				}
			} else {
				return <Outlet />;
			}
		}
	};

	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading}>
			{getContent()}
		</LoadingPlaceholder>
	);
};

export default observer(PrivateLayout);
