import { Close } from "@mui/icons-material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
	Box,
	Chip,
	CircularProgress,
	FormControl,
	InputBase,
	MenuItem,
	Select,
	SelectChangeEvent,
	SelectProps,
	Typography,
	styled,
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import BriaInput from "../BriaInput/BriaInput";
import styles from "./BriaDropDown.module.scss";

export type DropDownItem = {
	key: string;
	value: string | number;
};

interface IProps<T extends string | string[] | undefined> {
	value?: T;
	handleChange: (event: SelectChangeEvent<T>) => void;
	handleDelete?: (key: string | number) => void;
	onSearchChange?: (event: any) => void;
	loading?: boolean;
	items: DropDownItem[];
	height?: string;
	width?: string;
	className?: string;
	disabled?: boolean;
	hideArrowIcon?: boolean;
	border?: string;
	icon?: any;
	searchable?: boolean;
	debounceSearch?: boolean;
}

const BriaDropdown = <T extends string | string[] | undefined>({
	value,
	handleChange,
	handleDelete,
	onSearchChange,
	loading,
	items,
	width,
	height,
	className,
	disabled,
	hideArrowIcon,
	border,
	icon,
	searchable = false,
	debounceSearch = false,
	...rest
}: IProps<T> & SelectProps<T>) => {
	const [searchText, setSearchText] = useState<string>();
	const selectedItems = Array.isArray(value)
		? items.filter((item) => value.includes(item.value as string))
		: items.find((item) => item.value === value);

	const onSearchChangeDebounced = debounceSearch
		? useMemo(
				() =>
					debounce((text: string) => {
						onSearchChange && onSearchChange(text);
					}, 500),
				[onSearchChange],
		  )
		: onSearchChange;

	return (
		<StyledFormControl className={className} width={width} height={height}>
			<StyledSelect
				displayEmpty={!!rest.placeholder}
				value={value}
				onChange={handleChange}
				IconComponent={loading ? DropDownLoading : ExpandMoreRoundedIcon}
				hideArrowIcon={hideArrowIcon || disabled}
				disabled={disabled}
				renderValue={() =>
					(!Array.isArray(selectedItems) && selectedItems) ||
					(Array.isArray(selectedItems) && selectedItems.length > 0) ? (
						<>
							{Array.isArray(selectedItems) ? (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selectedItems.map((item: DropDownItem) => (
										<Chip
											key={item.key}
											label={item.key}
											deleteIcon={
												<Box onMouseDown={(e) => e.stopPropagation()}>
													<Close sx={{ width: "14px" }} />
												</Box>
											}
											onDelete={() => {
												handleDelete?.(item.value);
											}}
										/>
									))}
								</Box>
							) : (
								<Box className={styles.inputStyle}>
									{icon}
									<Typography className={styles.lableStyle}>{selectedItems.key}</Typography>
								</Box>
							)}
						</>
					) : (
						rest.placeholder
					)
				}
				MenuProps={{
					style: {
						marginTop: 4,
					},
				}}
				input={border ? <SelectInputBase border={border} /> : undefined}
				{...rest}
			>
				{searchable && (
					<Box onKeyDown={(e) => e.stopPropagation()}>
						<BriaInput
							type="text"
							value={searchText}
							onChange={(e: any) => {
								setSearchText(e.target.value);
								onSearchChangeDebounced && onSearchChangeDebounced(e.target.value);
							}}
							className={styles.customBriaInput}
						/>
					</Box>
				)}
				{loading && <StyledMenuItem disabled>Loading..</StyledMenuItem>}
				{items.map((item) => (
					<StyledMenuItem key={item.key} value={item.value}>
						{item.key}
					</StyledMenuItem>
				))}
			</StyledSelect>
		</StyledFormControl>
	);
};

export default observer(BriaDropdown);

const StyledFormControl = styled(FormControl)(({ width, height }: { width?: string; height?: string }) => ({
	width: width ?? "auto",
	"& .MuiInputBase-root": {
		height: height ?? "40px",
		minWidth: "120px",
		fontSize: "14px",
		fontWeight: "400",
		justifyContent: "center",
	},
}));

const SelectInputBase = styled(InputBase)(({ border }: { border?: string }) => ({
	border: border ? border : "initial",
	"& > div": {
		padding: "16.5px 14px",
	},
}));

const StyledSelect = styled(Select<any>, { shouldForwardProp: (props) => props != "hideArrowIcon" })(
	({ hideArrowIcon }: { hideArrowIcon?: boolean }) => ({
		width: "auto",
		"&.Mui-focused": {
			".MuiSvgIcon-root": {
				fill: "#5300C9",
			},
		},
		".MuiSelect-icon": {
			display: hideArrowIcon ? "none" : "block",
		},
	}),
);

const StyledMenuItem = styled(MenuItem)({
	fontWeight: 500,
	paddingTop: 8,
	paddingBottom: 8,
	fontSize: "14px",
	color: "#5B5B5B",
	"&.Mui-selected": {
		color: "#1A0638",
		fontWeight: "bold",
	},
});

const DropDownLoading = styled(CircularProgress)(({ theme }) => ({
	color: `${theme.palette.primary.dark} !important`,
	width: "1em !important",
	height: "1em !important",
	right: "13px !important",
}));
