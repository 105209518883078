export enum Environment {
	local = "local",
	integration = "integration",
	production = "production",
}

export const ENV_VARS = {
	VITE_PLATFORM_API_URL: import.meta.env.VITE_PLATFORM_API_URL,
	VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
};

export function getCurrentEnv() {
	return ENV_VARS.VITE_ENVIRONMENT ?? Environment.local;
}

export function getIframeHost() {
	switch (getCurrentEnv()) {
		case Environment.local:
			return "http://localhost:3000";
		case Environment.integration:
			return "https://int.embedded.bria.ai";
		default:
			return "https://prod.embedded.bria.ai";
	}
}

export function isLocal() {
	return getCurrentEnv() === Environment.local;
}

export function isIntegration() {
	return getCurrentEnv() === Environment.integration;
}

export function isProduction() {
	return getCurrentEnv() === Environment.production;
}

export function isCloudEnv() {
	return isProduction() || isIntegration();
}
