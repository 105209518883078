import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import styles from "./AppLayout.module.scss";

type LayoutProps = {
	showPageDropdown?: boolean;
};

const Layout = ({ showPageDropdown }: LayoutProps) => {
	return (
		<>
			<Header showPageDropdown={showPageDropdown} />
			<div className={styles.sidePagesContainer}>
				<Outlet />
			</div>
		</>
	);
};

export default observer(Layout);
