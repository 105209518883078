import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import LinkConstants from "../../../constants/LinkConstants.ts";
import classes from "./Agreement.module.scss";

interface IProps {
	showGetUpdated?: boolean;
	getUpdated: boolean;
	setGetUpdated: Dispatch<SetStateAction<boolean>>;
}

const Agreement: React.FC<IProps> = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Typography className={classes.typo3}>
				{t("bySubmitting")}

				<Link
					onClick={(_e: React.MouseEvent<any>) => {
						window.open(LinkConstants.PRIVACY_POLICY, "_blank");
					}}
					className={classes.link}
				>
					{t("privacyPolicy")}
				</Link>

				{t("and")}

				<Link
					onClick={(_e: React.MouseEvent<any>) => {
						window.open(`${LinkConstants.TERMS_AND_CONDITIONS_LINK}`, "_blank");
					}}
					className={classes.link}
				>
					{t("termsOfService")}
				</Link>
			</Typography>
		</Box>
	);
};

export default Agreement;
