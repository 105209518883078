import { matchPath } from "react-router-dom";
import RouterConstants from "../constants/RouterConstants";

export function capitalizeFirstLetter(text: string) {
	return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
}

export const getCurrentRoute = () => {
	const currentRoute = Object.entries(RouterConstants).find((route) =>
		matchPath(
			{
				path: route[1].path,
				exact: route[1].exact,
				strict: route[1].strict,
			},
			window.location.pathname,
		),
	);
	return currentRoute?.[1];
};
