import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Form, Formik, FormikErrors } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import ResetPasswordConfirmation from "../../../components/common/ResetPasswordConfirmation/ResetPasswordConfirmation.tsx";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import { isValidEmail } from "../../../utils/validators.ts";
import classes from "./ForgotPassword.module.scss";

interface FormValues {
	email: string;
	password: string;
}

const ForgotPassword = () => {
	const initialValues = {
		email: "",
	};
	const { t } = useTranslation();
	const { loading, errorMessage, resetPassword } = useAuthService();
	const [sendResetEmail, setSendResetEmail] = useState<{
		isEmailSent: boolean;
		userEmail: string;
	}>({ isEmailSent: false, userEmail: "" });

	const handleSubmit = async (values: any) => {
		resetPassword(values.email)
			.then(() => {
				setSendResetEmail({
					isEmailSent: true,
					userEmail: values.email,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	if (sendResetEmail.isEmailSent && !errorMessage) {
		return <ResetPasswordConfirmation email={sendResetEmail.userEmail} />;
	}

	return (
		<Box className={classes.content}>
			<Typography className={classes.mainTitle}>{t("forgotPassword")}</Typography>
			<Typography className={classes.subTitle}>{t("pleaseEnterYourEmailToReceiveAPasswordResetLink")}</Typography>

			<Formik
				initialValues={initialValues}
				validateOnMount={false}
				validateOnChange={false}
				validateOnBlur={false}
				validate={(values) => {
					const errors: FormikErrors<FormValues> = {};
					if (!values.email) {
						errors.email = t("emailIsRequired");
					} else if (!isValidEmail(values.email)) {
						errors.email = t("invalidEmail");
					}
					return errors;
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, handleChange, handleBlur }) => (
					<Form className={classes.form} noValidate>
						<Box className={classes.formElements}>
							<BriaInput
								id="email"
								label={t("email")}
								onChange={handleChange}
								error={(Boolean(errors.email) && touched.email) as boolean}
								onBlur={handleBlur}
								helperText={touched.email ? errors.email : ""}
								className={classes.textField}
							/>

							{errorMessage && (
								<Typography className={classes.error} color="secondary">
									<ErrorIcon className={classes.errorIcon} />
									{errorMessage}
								</Typography>
							)}

							<BriaButton
								buttonType={BUTTONTYPES.PRIMARY}
								type="submit"
								variant="contained"
								color="secondary"
								fullWidth={true}
							>
								{t("sendLink")}
								<Box className={classes.circleLoader}>
									{loading && <CircularProgress color="inherit" size={25} />}
								</Box>
							</BriaButton>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default ForgotPassword;
