import { makeAutoObservable } from "mobx";
import AuthStore, { IAuthStore } from "../pages/auth/store";
import AppStore, { IAppStore } from "../pages/store";

export interface IRootStore {
	appStore: IAppStore;
	authStore: IAuthStore;
}

export default class RootStore implements IRootStore {
	appStore = new AppStore() as IAppStore;
	authStore = new AuthStore() as IAuthStore;

	constructor() {
		makeAutoObservable(this);
	}
}
