export enum IframePostMessageTypes {
	InitialLoadComplete = "Bria_InitialLoadComplete",
	GalleryOpen = "Bria_GalleryOpen",
	CampaignOpen = "Bria_CampaignOpen",
	Auth = "Bria_Auth",
	AuthInit = "Bria_AuthInit",
}

export enum PagesEnum {
	Gallery = "gallery",
	Playground = "playground",
	Assets = "assets",
	Campaign = "campaign",
}
