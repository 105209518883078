import {
	BrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import smartlookClient from "smartlook-client";
import ForgotPasswordScreen from "./pages/auth/ForgotPassword/ForgotPassword.tsx";
import LoginScreen from "./pages/auth/Login/Login.tsx";
import RegisterScreen from "./pages/auth/Register/Register.tsx";
import PasswordResetScreen from "./pages/auth/ResetPassword/ResetPassword.tsx";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./assets/styles/main.scss";
import { ENV_VARS, getCurrentEnv, isCloudEnv, isIntegration, isLocal } from "./config/env";
import RouterConstants from "./constants/RouterConstants.ts";
import AppLayout from "./layout/AppLayout/AppLayout.tsx";
import AuthLayout from "./layout/AuthLayout/AuthLayout.tsx";
import PrivateLayout from "./layout/PrivateLayout/PrivateLayout.tsx";
import UserInfo from "./pages/auth/UserInfo/UserInfo.tsx";
import Campaign from "./pages/Campaign/Campaign.tsx";
import GenerateImages from "./pages/GenerateImages/GenerateImages.tsx";
import Home from "./pages/Home/Home.tsx";

let PlatformRoutes = Routes;
if (isCloudEnv()) {
	smartlookClient.init("ec245a49a6bf8b418a2b34e773128025ec739027");
	smartlookClient.record({
		forms: true,
		ips: true,
		numbers: true,
		emails: true,
		api: true,
	});

	Sentry.init({
		dsn: "https://32df6e7b9dab6be875c86509240c9a14@o417868.ingest.sentry.io/4505997423345664",
		integrations: [
			new Sentry.BrowserProfilingIntegration(), // for profiling, "Document-Policy" response header should be set to "js-profiling"
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			new Sentry.Replay({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [window.location.origin, ENV_VARS.VITE_PLATFORM_API_URL ?? ""],
			}),
		],
		debug: isIntegration() || isLocal(),
		environment: getCurrentEnv(),

		tracesSampleRate: 0.01,
		profilesSampleRate: 1.0, // tracesSampleRate * profilesSampleRate

		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
	});
	PlatformRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
}

function App() {
	return (
		<>
			<BrowserRouter>
				<PlatformRoutes>
					<Route element={<AuthLayout />}>
							<Route path={RouterConstants.LOGIN.path} element={<LoginScreen />} />
							<Route path={RouterConstants.SIGNUP.path} element={<RegisterScreen />} />
							<Route path={RouterConstants.FORGOT_PASSWORD.path} element={<ForgotPasswordScreen />} />
							<Route path={RouterConstants.PASSWORD_RESET.path} element={<PasswordResetScreen />} />
						</Route>
					<Route element={<PrivateLayout ignoreUserChecks />}>
						{/* Authenticated user is required */}
						<Route element={<AuthLayout ignoreRedirect />}>
							<Route path={RouterConstants.USER_INFO.path} element={<UserInfo />} />
						</Route>
					</Route>
					<Route element={<PrivateLayout />}>
						{/* Authenticated user is required */}
						<Route element={<AppLayout />}>
							<Route path={RouterConstants.HOME.path} element={<Home />} />
						</Route>
						<Route element={<AppLayout showPageDropdown={true} />}>
							<Route path={RouterConstants.GENERATE_IMAGE.path} element={<GenerateImages />} />
							<Route path={RouterConstants.CAMPAIGN.path} element={<Campaign />} />
						</Route>
						{/* Authenticated user is required */}
					</Route>
					<Route path="*" element={<Navigate to={RouterConstants.HOME.path} />} />
				</PlatformRoutes>
			</BrowserRouter>

			<ToastContainer
				className="toast"
				position="top-center"
				autoClose={3000}
				hideProgressBar
				closeOnClick
				pauseOnHover={false}
				draggable={false}
				icon={false}
			/>
		</>
	);
}

export default isCloudEnv() ? Sentry.withProfiler(App) : App;
