class User {
	userName: string;
	uid: string;
	profilePicture: string;
	role: string;
	company: string;
	userRole: string;
	getInfo: boolean;
	email: string;
	settings?: any;
	user_organizations?: any;

	constructor(
		userName: string,
		uid: string,
		profilePicture: string,
		role: string,
		company: string,
		userRole: string,
		getInfo: boolean,
		email: string,
		settings?: any,
		user_organizations?: any
	) {
		this.uid = uid;
		this.userName = userName;
		this.profilePicture = profilePicture;
		this.role = role;
		this.company = company;
		this.userRole = userRole;
		this.getInfo = getInfo;
		this.email = email;
		this.settings = settings;
		this.user_organizations = user_organizations;
	}

	isSuperAdmin() {
		return this.role === "admin";
	}
}

export default User;
