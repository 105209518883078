import { Theme, ThemeProvider, createTheme } from "@mui/material";
import i18n from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import { lightTheme } from "./constants/LightTheme";
import "./index.module.scss";
import MobxProvider from "./mobx/store-provider";
import common_ja from "./translations/ja.json";

i18n.init({
	resources: {
		ja: {
			translation: common_ja,
		},
		// Add translations for other languages here
	},
	lng: "ja", // Set the default language
	fallbackLng: "ja",
	interpolation: {
		escapeValue: false, // Allows using HTML in translations
	},
});

const theme: Theme = createTheme(lightTheme);

ReactDOM.createRoot(document.getElementById("root")!).render(
	<MobxProvider>
		<I18nextProvider i18n={i18n}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
			,
		</I18nextProvider>
	</MobxProvider>,
);
