import axios from "axios";
import { getIdToken } from "firebase/auth";
import { getSelectedOrganization } from "../helpers/localStorage";
import { showErrorToast } from "../utils/toast";
import { ENV_VARS } from "./env";
import { firebaseAuth } from "./firebase";

export async function BriaAxios() {
	const currentUser = firebaseAuth.currentUser;
	const authorization = currentUser ? await getIdToken(currentUser) : "";
	const selectedOrg = getSelectedOrganization();
	const instance = axios.create({
		baseURL: ENV_VARS.VITE_PLATFORM_API_URL,
		headers: {
			Authorization: authorization,
			"Org-Id": selectedOrg?.organization?.uid,
			"Content-Type": "application/json",
		},
	});
	instance.interceptors.response.use(null, (error) => {
		if (error.code !== "ERR_CANCELED") {
			const expectedError = error.response && error.response >= 400 && error.response < 500;
			if (!expectedError) {
				showErrorToast(undefined, "123");
			}
		}

		return Promise.reject(error);
	});
	return instance;
}
