import { UserOrganization } from "../models/organization";

export const getSelectedOrganization = () => {
	return {
		organization: {
			uid: "be652351-17b5-444e-8b8b-c126f33ed2e2",
			name: "Accenture"
		},
		role: "user"
	} as UserOrganization;
};
