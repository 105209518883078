import { ThemeOptions } from "@mui/material";

export const lightTheme: ThemeOptions = {
	palette: {
		mode: "light",
		common: {
			white: "#FFFFFF",
			black: "#495057",
		},
		primary: {
			light: "#D80067",
			main: "#8800FF",
			dark: "#5300C9",
		},
		secondary: {
			main: "#D80067",
		},
		text: {
			primary: "#495057",
			secondary: "#ABB5BE",
		},
		error: {
			main: "#DC3545",
		},
		warning: {
			main: "#FFC107",
		},
		success: {
			main: "#28A745",
		},
		background: {
			default: "radial-gradient(60.83% 60.83% at 50% 39.17%, #FFFFFF 21.87%, #EDEDED 100%)",
			paper: "#FFFFFF",
		},
	},
	typography: {
		fontFamily: "Montserrat",
	},
	shape: {
		borderRadius: 10,
	},
	components: {
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: "14px",
					color: "#5B5B5B",
				},
			},
		},
	},
};
