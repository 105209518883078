import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CampaignImage from "../../assets/images/svgs/Campaign - future.svg";
import GenerateImage from "../../assets/images/svgs/Generate Images.svg";
import Logo from "../../assets/images/svgs/Bria-logo.svg";
import { HomeCardComponent } from "../../components/Home/HomeCardComponent";
import { LoadingPlaceholder } from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../constants/RouterConstants";
import { HomeCard } from "../../models/homeCard.ts";
import styles from "./Home.module.scss";

const Home = () => {
	const { t: globalT } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "homePage" });
	const navigate = useNavigate();
	const [numberOfLoadedImages, setNumberOfLoadedImages] = useState(0);

	const cardsList: HomeCard[] = [
		{
			title: globalT("generateImages"),
			description: t("generateImagesDesc"),
			image: GenerateImage,
			onClick: () => {
				navigate(RouterConstants.GENERATE_IMAGE.path);
			},
		},
		{
			title: globalT("campaign"),
			description: t("campaignDesc"),
			image: CampaignImage,
			onClick: () => {
				navigate(RouterConstants.CAMPAIGN.path);
			},
		},
	];

	return (
		<LoadingPlaceholder
			isLoading={numberOfLoadedImages < cardsList.length}
			alwaysRenderChildren={true}
			className={styles.loaderStyle}
		>
			<Box className={styles.container}>
				<Box className={styles.titleContainer}>
					<img src={Logo} className={styles.logo}></img>
					<Typography className={styles.title}>{t("accentureAiHub")}</Typography>
				</Box>
				<Box className={styles.cardsContainer}>
					<Grid container spacing={4} className={styles.cardsGrid}>
						{cardsList.map((card: HomeCard) => (
							<HomeCardComponent
								title={card.title}
								description={card.description}
								image={card.image}
								onClick={card.onClick}
								onImageLoad={() => {
									setNumberOfLoadedImages((prevCount) => prevCount + 1);
								}}
							/>
						))}
					</Grid>
				</Box>
			</Box>
		</LoadingPlaceholder>
	);
};

export default observer(Home);
