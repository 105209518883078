import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getIframeHost, isProduction } from "../../../config/env";
import { firebaseAuth } from "../../../config/firebase";
import { IframePostMessageTypes, PagesEnum } from "../../../constants/IframeConstants";
import RouterConstants from "../../../constants/RouterConstants";
import { LoadingPlaceholder } from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./BriaIframe.module.scss";

interface IProps {
	iframeId: string;
	target: PagesEnum;
	vhash?: string;
	imageUrl?: string;
	selectedTab?: string;
	initUpload?: boolean;
}

const BriaIframe = (props: IProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const iframeRef = useRef<any>();
	const navigate = useNavigate();

	const iframeProps = {
		...props,
		sourceDomain: window.location.origin,
		disableNav: true,
	};
	const iframeQueryParams = Object.entries(iframeProps)
		.filter(([_key, val]) => typeof val === "boolean" ? true : val && val.length > 0)
		.map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
		.join("&");

	useEffect(() => {
		if (iframeRef.current) {
			window.addEventListener(
				"message",
				(event) => {
					if (event.data && event.data["type"]) {
						if (!isProduction()) {
							console.log(event.data);
						}
						if (event.data["type"] === IframePostMessageTypes.AuthInit) {
							firebaseAuth.currentUser?.getIdToken().then((token) => {
								iframeRef.current.contentWindow.postMessage(
									{
										type: IframePostMessageTypes.Auth,
										message: token,
									},
									getIframeHost(),
								);
							});
						} else if (event.data["type"] === IframePostMessageTypes.InitialLoadComplete) {
							setIsLoading(false);
						} else if (event.data["type"] === IframePostMessageTypes.GalleryOpen) {
							navigate(RouterConstants.GENERATE_IMAGE.path);
						} else if (event.data["type"] === IframePostMessageTypes.CampaignOpen) {
							navigate(RouterConstants.CAMPAIGN.path, {
								state: {
									vhash: event.data["inputData"]["vhash"],
									imageUrl: event.data["message"]["imageUrl"],
								},
							});
						}
					}
				},
				false,
			);
		}
	}, [iframeRef.current]);

	return (
		<Box className={styles.container}>
			<LoadingPlaceholder isLoading={isLoading} alwaysRenderChildren={true} className={styles.loadingPlaceholder}>
				<iframe ref={iframeRef} className={styles.iframe} src={`${getIframeHost()}?${iframeQueryParams}`} />
			</LoadingPlaceholder>
		</Box>
	);
};

export default observer(BriaIframe);
