import { makeAutoObservable } from "mobx";

export interface IAppStore {
	isLoading: boolean;
}

export default class AppStore implements IAppStore {
	isLoading: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}
}
