import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { firebaseAnalytics } from "../config/firebase";
import User from "../models/user";

export enum HUBSPOT_EVENTS {}

export enum ANALYTICS_EVENTS {
	REGISTER = "REGISTER",
	LOGIN = "LOGIN",
}

class Analytics {
	private static _instance: Analytics = new Analytics();

	// private hubspotService: HubspotService;

	constructor() {
		if (Analytics._instance) {
			throw new Error("Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.");
		}
		// this.hubspotService = new HubspotService();
		Analytics._instance = this;
	}

	public static getInstance(): Analytics {
		return Analytics._instance;
	}

	// private async setHubspotUser(currentUser: User) {
	// 	await this.hubspotService.upsertContact({
	// 		is_sso: true,
	// 	});
	//
	// 	if (currentUser) {
	// 		const splittedUserName = currentUser.userName.split(" ");
	// 		const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
	// 		_hsq.push([
	// 			"identify",
	// 			{
	// 				contact_source: `${isProduction() ? "prod" : "int"}_platform_sso`,
	// 				email: currentUser.email,
	// 				firstname: splittedUserName[0],
	// 				lastname: splittedUserName.filter((_part, index) => index !== 0).join(" "),
	// 				company: currentUser.company,
	// 			},
	// 		]);
	// 	}
	// }

	// public async logHubspotEvent(eventName: HUBSPOT_EVENTS, eventValue: any = true) {
	// 	await this.hubspotService.upsertContact({ is_sso: true, extra_properties: { [eventName]: eventValue } });
	// }

	public async setUser(currentUser: User | null) {
		if (currentUser) {
			setUserProperties(firebaseAnalytics, {
				role: currentUser.role,
				company: currentUser.company,
				user_domain: currentUser.email.split("@").pop(),
				user_id: currentUser.uid,
			});
			setUserId(firebaseAnalytics, currentUser.uid);
			// await this.setHubspotUser(currentUser);
		}
	}

	public logRegistrationEvent() {
		this.logEvent(ANALYTICS_EVENTS.REGISTER);
		(window as any).lintrk("track", {
			conversion_id: 13339457,
		});
	}

	public logEvent(eventName: ANALYTICS_EVENTS, eventParams?: { [key: string]: any }) {
		logEvent(firebaseAnalytics, eventName, eventParams);
	}

	public fireScreenView(screenName: string) {
		const props = {
			firebase_screen: screenName,
			page_title: screenName,
			screen_name: screenName,
			firebase_screen_class: screenName.replace(" ", "_"),
			screen_class: screenName.replace(" ", "_"),
		};
		logEvent(firebaseAnalytics, "screen_view", props);
		const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
		_hsq.push(["setPath", window.location.pathname]);
		_hsq.push(["trackPageView"]);
	}
}

export default function getAnalyticsInstance() {
	return Analytics.getInstance();
}
