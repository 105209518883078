import { onAuthStateChanged } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import { BriaAxios } from "../../config/axios";
import { firebaseAuth } from "../../config/firebase.ts";
import getAnalyticsInstance from "../../helpers/analytics.ts";
import { getUserWithUID } from "../../helpers/firebase.ts";
import { UserOrganization } from "../../models/organization.ts";
import User from "../../models/user.ts";

export interface IAuthStore {
	user: User | null;
	isLoggedIn: boolean;
	isLoading: boolean;

	logout(): Promise<void>;

	setUser(user: User | null): Promise<void>;

	createOrganization(org_name: string): Promise<any>;

	searchOrganizations(query: string, signal?: AbortSignal): Promise<UserOrganization[]>;

	setPostRegistrationConfigs(): Promise<void>;

	isError: boolean;
}

export default class AuthStore implements IAuthStore {
	user: User | null = null;
	isLoggedIn = false;
	isError = false;
	isLoading = true;

	constructor() {
		makeAutoObservable(this);
		onAuthStateChanged(firebaseAuth, (user) => {
			if (user) {
				this.isLoading = true;
				this.isError = false;

				getUserWithUID(user.uid)
					.then((userObject) => {
						if (userObject) {
							this.setUser(userObject);
						} else {
							this.setUser(null);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.setUser(null);
			}
		});
	}

	logout = async (): Promise<void> => {
		try {
			await firebaseAuth.signOut();
			this.isLoggedIn = false;
			localStorage.clear();
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	setUser = async (user: User | null): Promise<void> => {
		try {
			this.user = user;
			if (user != null) {
				await getAnalyticsInstance().setUser(user);
			}
			this.isLoggedIn = user !== null;
			this.isLoading = false;
			this.isError = false;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	createOrganization = async (org_name: string): Promise<any> => {
		this.isLoading = false;
		this.isError = false;
		const data = {
			name: org_name,
		};
		try {
			const response = await (await BriaAxios()).post(`/create_organization/`, data);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
			if (this.user) {
				getUserWithUID(this.user.uid).then((userObject) => {
					if (userObject) {
						this.setUser(userObject);
					} else {
						this.setUser(null);
					}
				});
			}
			return response.data;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	searchOrganizations = async (query: string, signal?: AbortSignal) => {
		const res = await (
			await BriaAxios()
		).get("/search_organizations/", {
			params: {
				query,
			},
			signal,
		});
		return res.data;
	};

	setPostRegistrationConfigs = async (): Promise<void> => {
		try {
			await (await BriaAxios()).post("/users/set_post_registration_configs", {});
		} catch (e) {
			return Promise.reject(e);
		}
	};
}
