import { Box, Grid, Paper, Typography } from "@mui/material";
import styles from "./HomeCardComponent.module.scss";

interface IProps {
	title: string;
	description: string;
	image: string;
	onClick: () => void;
	onImageLoad?: () => void;
}

export const HomeCardComponent = ({ title, description, image, onClick, onImageLoad }: IProps) => {
	return (
		<Grid item xs={12} sm={6} md={6} lg={3} xl={3} className={styles.container} onClick={onClick}>
			<Paper elevation={0} className={styles.paper}>
				<Box className={styles.textAndButtonContainer}>
					<Typography className={styles.title}>{title} </Typography>
					<Typography className={styles.description}>{description}</Typography>
				</Box>
				<Box className={styles.imgContainter}>
					<img className={styles.image} src={image} alt="" onLoad={onImageLoad} />
				</Box>
			</Paper>
		</Grid>
	);
};
