export default class RouterConstants {
	public static readonly HOME = {
		path: "/",
	};

	public static readonly FORGOT_PASSWORD = {
		path: "/forgot-password",
	};

	public static readonly SIGNUP = {
		path: "/register",
	};

	public static readonly LOGIN = {
		path: "/login",
	};

	public static readonly PASSWORD_RESET = {
		path: "/auth/",
	};

	public static readonly GENERATE_IMAGE = {
		path: "/generate-image",
		nameKey: "generateImages",
		showInPageDropdown: true,
	};

	public static readonly CAMPAIGN = {
		path: "/campaign",
		nameKey: "campaign",
		showInPageDropdown: true,
	};

	public static readonly USER_INFO = {
		path: "/user-info",
	};
}
